import { IOptions } from "../../types/Options.types";

const systemOptions: IOptions[] = [
	{ id: "abs", value: "ABS" },
	{ id: "acessorios", value: "Acessórios" },
	{ id: "adaptacao", value: "Adaptação" },
	{ id: "alimentacao", value: "Alimentação Combustível" },
	{ id: "alinhamento", value: "Alinhamento" },
	{ id: "alternador", value: "Alternador" },
	{ id: "amortecedor", value: "Amortecedor" },
	{ id: "ar", value: "Ar Condicionado" },
	{ id: "arrefecimento", value: "Arrefecimento" },
	{ id: "socorro", value: "Socorro Externo" },
	{ id: "balanceamento", value: "Balanceamento" },
	{ id: "bateria", value: "Bateria" },
	{ id: "cabos", value: "Cabos" },
	{ id: "cambagem", value: "Cambagem" },
	{ id: "carburador", value: "Carburador" },
	{ id: "carenagem", value: "Carenagem" },
	{ id: "carroceria", value: "Carroceria" },
	{ id: "caster", value: "Caster" },
	{ id: "checklist", value: "Checklist" },
	{ id: "diagnostico", value: "Diagnóstico" },
	{ id: "direcao", value: "Direção" },
	{ id: "eletrica", value: "Elétrica" },
	{ id: "embreagem", value: "Embreagem" },
	{ id: "escapamento", value: "Escapamento" },
	{ id: "freios", value: "Freios" },
	{ id: "funilaria", value: "Funilaria" },
	{ id: "ignicao", value: "Ignição" },
	{ id: "iluminacao", value: "Iluminação" },
	{ id: "injecao", value: "Injeção Eletrônica" },
	{ id: "modulos", value: "Módulos" },
	{ id: "motor", value: "Motor" },
	{ id: "oleo", value: "Óleo" },
	{ id: "painel", value: "Painel" },
	{ id: "preventiva", value: "Preventiva" },
	{ id: "revisao", value: "Revisão" },
	{ id: "rolamento", value: "Rolamento" },
	{ id: "sincronismo", value: "Sincronismo de motores" },
	{ id: "suspensao", value: "Suspensão" },
	{ id: "transmissao", value: "Transmissão"}
];

export const serviceAttributes = {
	systemOptions: systemOptions,
};
